import React from "react";
import "./manucagenew.css";
import tapercagenobg from "./assets/manu/tapercagenobg.png";
import Lottie from "lottie-react";
import gear2 from "./assets/manu/gear2.json";
import taperprocess from './assets/manu/taperprocess.png';
import { FaCar } from 'react-icons/fa';
import { PiPlant } from "react-icons/pi"
import newmanu from './assets/manu/newmanu.jpeg'
// import manulogo from './assets/manu/manulogo.png'


const TaperCage = () => {

  return (
    <div className="manu-main-container">
    <img src={newmanu} alt="Manu Logo" className="new-manu-logo"/>
   <div className="grid-container">
     <div className="box box-1">
       <div>
       <h2>PROCESS FLOW</h2>
       </div>
       <div>
     <img src={taperprocess} alt="" srcset="" />
     </div>
     </div>
     <div className=" box-2">TAPER CAGE</div>
     <div className="box box-3">
       <img src={tapercagenobg} alt="" srcset="" />
     </div>
     <div className="box box-4">
       <ul>
         <li>
           {" "}
           <Lottie animationData={gear2} className="gear2" />
           Total Emission (Production) <br></br>
         </li>
         <li className="box-highlighted-text">
           {" "}
           <span>0.84 </span>tCO₂
         </li>
         <li>
           {" "}
           <Lottie animationData={gear2} className="gear2" /> Per Piece
           Emission<br></br>
         </li>
         <li className="box-highlighted-text">
           {" "}
           <span>6.00 g </span>CO₂e
         </li>{" "}
         <li>
           {" "}
           <Lottie animationData={gear2} className="gear2" /> Specific Energy
           Consumption<br></br>
         </li>
         <li className="box-highlighted-text">
           {" "}
           <span>17.15 </span>W
         </li>
       </ul>
     </div>
     <div className="box box-5">
    <div>
    <FaCar />  <span>4221.53 KM</span>
    <p>The Passenger Car shall be driven to emit this<br/> amount of Carbon.</p>
    </div>
    <div>=</div>
    <div>
    <PiPlant /> <span> 34</span>
    <p>Number of trees wll be required to absorb this<br/> amount of Carbon.</p>
    </div>
     </div>
   </div>
 </div>
  );
};

export default TaperCage;

function SocialMediaFeed() {
  return (
    <div className="social-media-container">
      <div className="social-media-feed">
        <iframe
          src="https://widgets.sociablekit.com/linkedin-page-posts/iframe/25510645"
          frameborder="0"
          width="450"
          height="600"
          title="EcoIndex linkedin"
        ></iframe>
      </div>
      <div className="social-media-feed">
        <iframe
          src="https://widgets.sociablekit.com/instagram-feed/iframe/25510692"
          frameborder="0"
          width="450"
          height="600"
          title="EcoIndex instagram"
        ></iframe>
      </div>
      <div className="social-media-feed">
        <iframe
          src="https://widgets.sociablekit.com/twitter-feed/iframe/25510697"
          frameborder="0"
          width="450"
          height="600"
          title="EcoIndex twitter"
        ></iframe>
      </div>
    </div>
  );
}

export default SocialMediaFeed;

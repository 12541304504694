import React from "react";
import "./manucagenew.css";
import polyamidenobg from "./assets/manu/polyamidenobg.png";
import Lottie from "lottie-react";
import gear2 from "./assets/manu/gear2.json";
import polyprocess from './assets/manu/polyprocess.png';
import { FaCar } from 'react-icons/fa';
import { PiPlant } from "react-icons/pi"
// import manulogo from './assets/manu/manulogo.png'
import newmanu from './assets/manu/newmanu.jpeg'

const PolyamideCage = () => {


  return (
    <div className="manu-main-container">
    <img src={newmanu} alt="Manu Logo" className="new-manu-logo"/>
   <div className="grid-container">
     <div className="box box-1">
       <div>
       <h2>PROCESS FLOW</h2>
       </div>
       <div>
     <img src={polyprocess} alt="" srcset="" />
     </div>
     </div>
     <div className=" box-2">POLYAMIDE CAGE</div>
     <div className="box box-3">
       <img src={polyamidenobg} alt="" srcset="" />
     </div>
     <div className="box box-4">
       <ul>
         <li>
           {" "}
           <Lottie animationData={gear2} className="gear2" />
           Total Emission (Production) <br></br>
         </li>
         <li className="box-highlighted-text">
           {" "}
           <span>29.11 </span> tCO₂
         </li>
         <li>
           {" "}
           <Lottie animationData={gear2} className="gear2" /> Per Piece
           Emission<br></br>
         </li>
         <li className="box-highlighted-text">
           {" "}
           <span>1.01 g </span> CO₂e
         </li>{" "}
         <li>
           {" "}
           <Lottie animationData={gear2} className="gear2" /> Specific Energy
           Consumption<br></br>
         </li>
         <li className="box-highlighted-text">
           {" "}
           <span>2.88 </span> W
         </li>
       </ul>
     </div>
     <div className="box box-5">
    <div>
    <FaCar />  <span> 1,45,559.44 KM</span>
    <p>The Passenger Car shall be driven to emit this<br/> amount of Carbon.</p>
    </div>
    <div>=</div>
    <div>
    <PiPlant /> <span> 1164</span>
    <p>Number of trees wll be required to absorb this<br/> amount of Carbon.</p>
    </div>
     </div>
   </div>
 </div>
  );
};

export default PolyamideCage;

import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";

import sprout from "./assets/sprout.png";
import ds from "./assets/ds.png";
import Lottie from "lottie-react";
import reg from "./assets/anim/reg.json";
import paper from "./assets/anim/paper.json";
import wrong from "./assets/anim/wrong.json";
import right from "./assets/anim/right.json";
import bottle from "./assets/anim/bottle.json";
import meal from "./assets/anim/meal.json";
import banner from "./assets/anim/banner.json";
import recbanner from "./assets/anim/recbanner.json";
import ev from "./assets/anim/ev.json";
import EVicon from "./assets/anim/EVicon.json";
import bulb from "./assets/anim/bulb.json";
import energy from "./assets/anim/energy.json";
import monitor from "./assets/anim/monitor.json";
import fuel from "./assets/anim/fuel.json";
import bag from "./assets/anim/bag.json";
import cycle from "./assets/anim/cycle.json";
import AC from "./assets/anim/AC.json";
import train from "./assets/anim/train.json";
import food from "./assets/anim/food.json";
import low from "./assets/anim/low.json";
import shopping from "./assets/anim/shopping.json";
import rrr from "./assets/anim/rrr.json";
import plant from "./assets/anim/plant.json";
import clothes from "./assets/clothes.png";
import climate from "./assets/anim/climate.json";
import renew from "./assets/anim/renew.json";
import efficiency from "./assets/anim/efficiency.json";
import shipping from "./assets/anim/shipping.json";
import supply from "./assets/anim/supply.json";
import plasticbag from "./assets/anim/plasticbag.json";
import wastemethod from "./assets/anim/wastemethod";
import Pricing from "./Pricing.js";
import evcar from "./assets/anim/evcar.json";
import meet from "./assets/anim/meet.json";
import Footer from "./Footer.jsx";
import styles from "./landingPage.module.css";
import "./landingPage.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SocialMediaFeed from "./socialmediafeed.js";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Navigation,
  EffectCoverflow,
  Pagination,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import MarqueeImg from "./Marquee.js";

window.onload = function () {
  document.querySelectorAll(".scroll").forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    });
  });
};

const sendEmail = (e) => {
  e.preventDefault();

  const formData = new FormData(e.target);
  const data = {
    from_firstname: formData.get("from_firstname"),
    from_lastname: formData.get("from_lastname"),
    from_phonenumber: formData.get("from_phonenumber"),
    country: formData.get("country"),
    from_emailid: formData.get("from_emailid"),
    organization: formData.get("organization"),
    message: formData.get("message"),
  };

  console.log("Data being sent:", data);

  emailjs
    .send("service_nxepswl", "template_jumzlyr", data, "Ei4GRgZo9kOlQvyW9")
    .then((response) => {
      console.log("Email sent successfully", response);
      alert("Your message has been sent successfully!");
    })
    .catch((error) => {
      console.error("Email sending failed", error);
      alert("Sorry, something went wrong. Please try again later.");
    });
};

const LandingPage = () => {
  const location = useLocation();
  const [IDENTITY_BASE_URL, setIDENTITY_BASE_URL] = useState(
    process.env.REACT_APP_IDENTITY_BASE_URL + "/Identity/Account/Register"
  );

  const [elementIndex, setElementIndex] = useState(0);

  const elementPairs = [
    [
      <h1 className="text-paper">
        GO <br></br>PAPERLESS
      </h1>,
      <h3 className="sub-text-slide1">
        Embrace Digital Solutions to Reduce Paper Waste and Save Trees.
      </h3>,
      <Lottie animationData={paper} className="paper-img" />,
      <Lottie animationData={wrong} className="wrong-img" />,
    ],

    [
      <h1 className="text-paper">
        DIGITAL <br></br> REGISTRATION
      </h1>,
      <h3 className="sub-text-slide1">
        Go Green with Digital registration process.
      </h3>,
      <Lottie animationData={reg} className="reg-img" />,
      <Lottie animationData={right} className="right-img" />,
    ],

    [
      <h1 className="text-paper">
        SAY NO TO<br></br> PLASTIC BOTTLES
      </h1>,
      <h3 className="sub-text-slide1">
        Ditch Single-Use Plastics & Choose Sustainable Alternatives.
      </h3>,
      <Lottie animationData={bottle} className="bottle-img" />,
      <Lottie animationData={wrong} className="wrong-img" />,
    ],

    [
      <h1 className="text-paper">LOCAL FOOD</h1>,
      <h3 className="sub-text-slide1">Support Local & Eat Green food.</h3>,
      <Lottie animationData={meal} className="meal-img" />,
    ],

    [
      <h1 className="text-paper">
        RECYCLABLE <br></br> FABRIC BANNERS
      </h1>,
      <h3 className="sub-text-slide1">
        Use Banners Made of Eco-friendly & Recyclable Materials.
      </h3>,
      <Lottie animationData={banner} className="banner-img" />,
      <Lottie animationData={recbanner} className="recbanner-img" />,
    ],

    [
      <h1 className="text-paper">
        EV SHUTTLE <br></br> FOR PICK-UP & DROP
      </h1>,
      <h3 className="sub-text-slide1">
        Choose Electric Vehicles for a Lower Carbon Footprint
      </h3>,
      <Lottie animationData={EVicon} className="ev-icon" />,
      <Lottie animationData={ev} className="ev-img" />,
    ],

    [
      <h1 className="text-paper">
        OPTIMUM USE OF <br></br> ARTIFICIAL LIGHTING
      </h1>,
      <h3 className="sub-text-slide1">
        Save Energy with Smart Lighting Practices and Limit Use of Artificial
        Lighting.
      </h3>,
      <Lottie animationData={bulb} className="bulb-img" />,
    ],

    [
      <h1 className="text-paper">
        ENERGY <br></br> MONITORING
      </h1>,
      <h3 className="sub-text-slide1">Track, Optimize & Save Energy.</h3>,
      <Lottie animationData={energy} className="energy-img" />,
      <Lottie animationData={monitor} className="monitor-img" />,
    ],

    [
      <h1 className="text-paper">
        FUEL <br></br> MONITORING
      </h1>,
      <h3 className="sub-text-slide1">Monitor Fuel Usage to Cut Emissions.</h3>,
      <Lottie animationData={fuel} className="energy-img" />,
      <Lottie animationData={monitor} className="monitor-img" />,
    ],
  ];

  const slide2ElementPairs = [
    [
      <h1 className="text-slide2">
        CARRY <br />
        CLOTH BAGS
      </h1>,
      <h3 className="sub-text-slide2">Say No to Plastic with Cloth Bags.</h3>,
      <Lottie animationData={bag} className="bag-img" />,
    ],
    [
      <h1 className="text-slide2">
        OPT FOR
        <br />
        TWO-WHEELERS
      </h1>,
      <h3 className="sub-text-slide2">
        Choose bicycles or two-wheelers for short trips to minimize carbon
        emissions.
      </h3>,
      <Lottie animationData={cycle} className="cycle-img" />,
    ],

    [
      <h1 className="text-slide2">
        DRESS AS PER
        <br />
        THE CLIMATE
      </h1>,
      <h3 className="sub-text-slide2">
        Dress according to the Weather & Save Energy with Climate-Appropriate
        Clothing.
      </h3>,
      <Lottie animationData={climate} className="climate-img" />,
      <div className="clothes-img">
        <img src={clothes} className="clothes-img" alt="clothes" />
      </div>,
    ],

    [
      <h1 className="text-slide2">
        SET
        <br />
        AC TO 26°C
      </h1>,
      <h3 className="sub-text-slide2">
        Set Your AC to 26°C for Comfort and Efficiency.
      </h3>,
      <Lottie animationData={AC} className="AC-img" />,
    ],

    [
      <h1 className="text-slide2">
        CHOOSE
        <br />
        TRAIN OVER FLIGHTS
      </h1>,
      <h3 className="sub-text-slide2">
        Choose train travel over flights to reduce your carbon footprint
        significantly.
      </h3>,
      <Lottie animationData={train} className="train-img" />,
    ],

    [
      <h1 className="text-slide2">
        LOW-CARBON
        <br />
        FOOD CHOICES
      </h1>,
      <h3 className="sub-text-slide2">
        Opt for Plant-Based and Local Foods for a Greener Diets.
      </h3>,
      <Lottie animationData={low} className="low-img" />,
      <Lottie animationData={food} className="food-img" />,
    ],

    [
      <h1 className="text-slide2">
        LIMIT
        <br />
        ONLINE SHOPPING
      </h1>,
      <h3 className="sub-text-slide2">
        Reduce Waste and Emissions by Cutting Impulsive Online Orders
      </h3>,
      <Lottie animationData={shopping} className="shopping-img" />,
    ],

    [
      <h1 className="text-slide2">
        REDUCE,
        <br />
        REUSE, RECYCLE
      </h1>,
      <h3 className="sub-text-slide2">
        Practice the 3Rs to Minimize Waste and Conserve Resources.
      </h3>,
      <Lottie animationData={rrr} className="rrr-img" />,
    ],

    [
      <h1 className="text-slide2">
        PLANT
        <br />
        MORE TREES
      </h1>,
      <h3 className="sub-text-slide2">
        Plant Trees to Absorb Carbon and Purify the Air.
      </h3>,
      <Lottie animationData={plant} className="plant-img" />,
    ],
  ];

  const slide3ElementPairs = [
    [
      <h1 className="text-slide3">
        USING
        <br />
        RENEWABLE ENERGY
      </h1>,
      <h3 className="sub-text-slide3">
        Adopt solar, wind, and hydropower to cut emissions.
      </h3>,
      <Lottie animationData={renew} className="renew-img" />,
    ],
    [
      <h1 className="text-slide3">
        ENERGY <br />
        EFFICIENCY
      </h1>,
      <h3 className="sub-text-slide3">
        Use energy-saving tech to lower consumption.
      </h3>,
      <Lottie animationData={efficiency} className="efficiency-img" />,
    ],

    [
      <h1 className="text-slide3">
        OPTIMIZE <br />
        BUSINESS TRAVEL
      </h1>,
      <h3 className="sub-text-slide3">
        Reduce emissions with virtual meetings and eco-friendly transport.
      </h3>,
      <Lottie animationData={meet} className="meet-img" />,
      <Lottie animationData={evcar} className="evcar-img" />,
    ],

    [
      <h1 className="text-slide3">
        OPTIMIZE
        <br />
        SUPPLY CHAIN
      </h1>,
      <h3 className="sub-text-slide3">
        Streamline processes and choose sustainable suppliers.
      </h3>,
      <Lottie animationData={supply} className="supply-img" />,
    ],

    [
      <h1 className="text-slide3">
        WATERWAYS
        <br />
        SHIPPING
      </h1>,
      <h3 className="sub-text-slide3">
        Use sea transport to cut emissions over air freight.
      </h3>,
      <Lottie animationData={shipping} className="shipping-img" />,
    ],

    [
      <h1 className="text-slide3">
        PLASTIC-FREE
        <br />
        PACKAGING
      </h1>,
      <h3 className="sub-text-slide3">
        Switch to biodegradable or reusable packaging.
      </h3>,
      <Lottie animationData={plasticbag} className="plasticbag-img" />,
    ],

    [
      <h1 className="text-slide3">
        WAYS TO RECYLE
        <br />
        WASTE GENERATION
      </h1>,
      <h3 className="sub-text-slide3">
        Implement circular models to recycle and repurpose waste, reducing
        landfill use.
      </h3>,
      <Lottie animationData={wastemethod} className="wastemethod-img" />,
    ],
  ];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const eventId = params.get("eventId");
    const q2 = params.get("q2");

    if (eventId && q2) {
      setIDENTITY_BASE_URL(
        (prevURL) => prevURL + `?eventId=${eventId}&q2=${q2}`
      );
    }

    const interval = setInterval(() => {
      setElementIndex((prevIndex) => (prevIndex + 1) % elementPairs.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [location, elementPairs.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      setElementIndex(
        (prevIndex) => (prevIndex + 1) % slide2ElementPairs.length
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [slide2ElementPairs.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      setElementIndex(
        (prevIndex) => (prevIndex + 1) % slide3ElementPairs.length
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [slide3ElementPairs.length]);

  const navigate = useNavigate();

  const handleNavigateToAddInfo = () => {
    navigate("/newaddinfo");
  };


  const handleLogin = () => {
    navigate("/login");
    window.history.go(-1);
  };

  const handleSignUp = () => {
    window.location.href = IDENTITY_BASE_URL;
  };

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      await sendEmail(event);
      togglePopup();
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const featuresSwiperRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="landing-container">
      <div className="slides slide1">
        <div className="header">
          <div className="navbar-left">
            <img src={sprout} className="logo" alt="Ecoindex logo" />

            <ul className={`navlist ${menuOpen ? "open" : ""}`}>
              <li className="navitem">
                <a href="#events" className="navlinks scroll">
                  Events
                </a>
              </li>
              <li className="navitem">
                <a href="#individuals" className="navlinks scroll">
                  Individuals
                </a>
              </li>
              <li className="navitem">
                <a href="#organizations" className="navlinks scroll">
                  Organizations
                </a>
              </li>
              <li className="navitem">
                <a href="#pricing" className="navlinks scroll">
                  Pricing
                </a>
              </li>
              <li className="navitem">
                <a
                  href="/resources"
                  className="navlinks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Resources
                </a>
              </li>
            </ul>
          </div>
          <div className="navbar-right">
            <div className="login-btn">
              <button
                onClick={handleLogin}
                className={[styles.btn, styles.btn_light].join(" ")}
              >
                Log In
              </button>
            </div>
          </div>

          <div className="hamburger-menu" onClick={toggleMenu}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </div>

        <div className="main">
          <div className="main-text">
            <h1>
              What is Your <br></br> Carbon Footprint?
            </h1>
            <h3>Estimate the Environmental Impact</h3>

            <button class="learn-more">
              <span class="start-tracking-circle" aria-hidden="true">
                <span class="icon arrow"></span>
              </span>
              <span onClick={handleLogin} class="button-text-l">
                Start Tracking
              </span>
            </button>

            <div className="Schedule">
              <button class="schedule-button" onClick={togglePopup}>
                Schedule a Call
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  class="arrow"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M6.62 10.79a15.478 15.478 0 006.59 6.59l2.2-2.2a1 1 0 011.11-.23c1.19.5 2.48.77 3.78.77a1 1 0 011 1v3.36a1 1 0 01-.9 1c-9.05.81-16.42-6.56-15.61-15.61a1 1 0 011-.9H7a1 1 0 011 1c0 1.3.27 2.59.77 3.78a1 1 0 01-.23 1.11l-2.2 2.2z"
                    transform="rotate(50, 12, 12)"
                  ></path>
                </svg>
              </button>


              <button className="schedule-button" onClick={handleNavigateToAddInfo}>
                Calculate Your Travel Footprint
                
              </button>    

              {isOpen && (
                <div className="popup">
                  <div className="popup-content">
                    <span className="close-icon" onClick={togglePopup}>
                      &times;
                    </span>
                    <h2 className="popup-title">
                      Schedule a Call <i class="fa fa-phone"></i>
                    </h2>

                    <form className="popup-form" onSubmit={handleFormSubmit}>
                      <div className="input-row">
                        <div className="input-group half-width">
                          <label>First Name</label>
                          <input type="text" name="from_firstname" required />
                        </div>
                        <div className="input-group half-width">
                          <label>Last Name</label>
                          <input type="text" name="from_lastname" required />
                        </div>
                      </div>

                      <div className="input-row">
                        <div className="input-group half-width">
                          <label>Phone No.</label>
                          <input type="tel" name="from_phonenumber" required />
                        </div>
                        <div className="input-group half-width">
                          <label>Country</label>
                          <input type="text" name="country" required />
                        </div>
                      </div>

                      <div className="input-group">
                        <label>Email</label>
                        <input type="email" name="from_emailid" required />
                      </div>

                      <div className="input-group">
                        <label>Organization</label>
                        <input type="text" name="organization" required />
                      </div>

                      <div className="input-group">
                        <label>What do you want to know?</label>
                        <textarea name="message" required></textarea>
                      </div>

                      <div className="popup-buttons">
                        <button type="submit" className="submit-button">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="image">
            <img src={ds} alt="" />
          </div>
        </div>
      </div>

      <div className="slides slide2" id="events">
        <div className="main">
          <div className="image">
            <div className="rectangle-box1">
              {elementPairs[elementIndex][0]}
              {elementPairs[elementIndex][1]}
              {elementPairs[elementIndex][2]}
              {elementPairs[elementIndex][3]}
              {elementPairs[elementIndex][4]}
              {elementPairs[elementIndex][5]}
            </div>
          </div>

          <div className="form1 slide-right-content">
            <h1 className="heading1">
              Track Carbon Emissions for <br></br>
              <b>EVENTS</b>
            </h1>
            <p>
              EVENT industry is responsible for more than{" "}
              <b>10% of global CO2 emissions.</b> If you are organizing{" "}
              <b>
                a Conference, Corporate Event, Live Show, Exhibition, Sports
                Event, Tradeshow, Music Festival, or any other event
              </b>
              , TRACK your Event’s Carbon Emissions and gain valuable insights
              on reducing your environmental impact.
              <br></br> <br></br>
              <span>Organize Net Zero Carbon Events!</span>
              <br></br> <br></br>
            </p>

            <button class="learn-more">
              <span class="start-tracking-circle" aria-hidden="true">
                <span class="icon arrow"></span>
              </span>
              <span onClick={handleLogin} class="button-text-l">
                Start Tracking
              </span>
            </button>

            <div className="features">
              <div className="featuresHeading">
                <h2>Features:</h2>
              </div>
              <>
                <Swiper
                  effect={"coverflow"}
                  grabCursor={true}
                  centeredSlides={true}
                  loop={true}
                  slidesPerView={1.5}
                  coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 2,
                  }}
                  pagination={{ el: ".feature-pagination", clickable: true }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  onBeforeInit={(swiper) => {
                    featuresSwiperRef.current = swiper;
                  }}
                  modules={[Autoplay, EffectCoverflow, Navigation, Pagination]}
                  className="feature_swiper_container"
                >
                  <SwiperSlide>
                    {({ isActive }) => (
                      <p
                        className={`${isActive ? "" : "inactive_feature_card"}`}
                      >
                        Monitor Scope 1, Scope 2, and Scope 3 emissions
                        effortlessly.
                      </p>
                    )}
                  </SwiperSlide>
                  <SwiperSlide>
                    {({ isActive }) => (
                      <p
                        className={`${isActive ? "" : "inactive_feature_card"}`}
                      >
                        Utilize user-friendly forms to easily collect attendee
                        travel data and estimate related emissions.
                      </p>
                    )}
                  </SwiperSlide>
                  <SwiperSlide>
                    {({ isActive }) => (
                      <p
                        className={`${isActive ? "" : "inactive_feature_card"}`}
                      >
                        Generate custom links and QR codes to simplify attendee
                        data collection.
                      </p>
                    )}
                  </SwiperSlide>
                  <SwiperSlide>
                    {({ isActive }) => (
                      <p
                        className={`${isActive ? "" : "inactive_feature_card"}`}
                      >
                        AI-powered automated carbon report generation
                      </p>
                    )}
                  </SwiperSlide>
                  <SwiperSlide>
                    {({ isActive }) => (
                      <p
                        className={`${isActive ? "" : "inactive_feature_card"}`}
                      >
                        Track emissions from pre-event activities such as
                        meetings and marketing activities.
                      </p>
                    )}
                  </SwiperSlide>
                  <SwiperSlide>
                    {({ isActive }) => (
                      <p
                        className={`${isActive ? "" : "inactive_feature_card"}`}
                      >
                        Calculate waste-related emissions using standardized
                        values, if precise data is not available.
                      </p>
                    )}
                  </SwiperSlide>
                  <SwiperSlide>
                    {({ isActive }) => (
                      <p
                        className={`${isActive ? "" : "inactive_feature_card"}`}
                      >
                        Facilitates API integration with registration forms for
                        attendee data collection.
                      </p>
                    )}
                  </SwiperSlide>
                </Swiper>
              </>
              <div className="feature-controller">
                <div
                  className="feature-navigation"
                  onClick={() => featuresSwiperRef.current?.slidePrev()}
                >
                  <KeyboardArrowLeftIcon fontSize="large" />
                </div>
                <div className="pagination-container">
                  <div className="feature-pagination"></div>
                </div>
                <div
                  className="feature-navigation"
                  onClick={() => featuresSwiperRef.current?.slideNext()}
                >
                  <KeyboardArrowRightIcon fontSize="large" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="slides slide3" id="individuals">
        <div className="main">
          <div className="form1 slide-left-content">
            <h1 className="heading1">
              Track Carbon Emissions for <br></br>
              <b>INDIVIDUALS</b>
            </h1>
            <p>
              The average global carbon footprint per person is around
              <b> 4 tons </b>, and it needs to drop to{" "}
              <b>2 tons by 2050 to prevent a 2℃ rise in global temperatures.</b>{" "}
              Learn How to <b>Reduce Carbon Footprint</b> by tracking your
              carbon emissions and contributing to the global cause.
              <br></br> <br></br>
              <span>Every Action Counts! </span>
              <br></br> <br></br>
            </p>
            <button class="learn-more">
              <span class="start-tracking-circle" aria-hidden="true">
                <span class="icon arrow"></span>
              </span>
              <span onClick={handleLogin} class="button-text-l">
                Start Tracking
              </span>
            </button>
          </div>
          <div className="image">
            <div className="rectangle-box">
              {slide2ElementPairs[elementIndex][0]}
              {slide2ElementPairs[elementIndex][1]}
              {slide2ElementPairs[elementIndex][2]}
              {slide2ElementPairs[elementIndex][3]}
              {slide2ElementPairs[elementIndex][4]}
              {slide2ElementPairs[elementIndex][5]}
            </div>
          </div>
        </div>
      </div>

      <div className="slides slide4" id="organizations">
        <div className="main">
          <div className="image">
            <div className="rectangle-box">
              {slide3ElementPairs[elementIndex][0]}
              {slide3ElementPairs[elementIndex][1]}
              {slide3ElementPairs[elementIndex][2]}
              {slide3ElementPairs[elementIndex][3]}
            </div>
          </div>
          <div className="form1 slide-right-content">
            <h1 className="heading1">
              Track Carbon Emissions for <b>ORGANIZATIONS</b>
            </h1>
            <p>
              The Earth is now about <b> 1.1°C warmer</b> than it was in the
              late 1800s, and emissions continue to rise. To limit global
              warming to no more than <b> 1.5°C,</b> as outlined in the Paris
              Agreement, emissions must be reduced by <b>45% by 2030</b> and
              reach net zero by <b> 2050.</b> Discover effective Climate Change
              Solutions by calculating your organization's carbon emissions and
              exploring ways to reduce them. <br></br> <br></br>
              <span> Global Action is Needed! </span>
              <br></br> <br></br>
            </p>
            <button class="learn-more">
              <span class="start-tracking-circle" aria-hidden="true">
                <span class="icon arrow"></span>
              </span>
              <span onClick={handleLogin} class="button-text-l">
                Start Tracking
              </span>
            </button>
          </div>
        </div>
      </div>
<div className="marquee-box1">
<MarqueeImg></MarqueeImg>
</div>
     <section className="slides slide5" id="pricing">
        <Pricing />
      </section>

      <section className="slide6">
        <SocialMediaFeed />
        <Footer />
      </section>
      {/* Scroll-to-Top Button */}
      {showScrollToTop && (
        <button id="scrollToTopBtn" className="show" onClick={scrollToTop}>
          &#8679;
        </button>
      )}
    </div>
  );
};

export default LandingPage;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Login";
import "./App.css";
import EventsTable from "./EventsTable";
import AddEvent from "./AddEvent";
import DuringEventPlanning from "./DuringEventPlanning";
import PostEventPlanning from "./components/PostEventPlanning/PostEventPlanning.js";
import PreEventPlanning from "./PreEventPlanning";
import Individual from "./Individual";
import { EventProvider } from "./EventDetailsContext";
import EventDetails from "./EventDetails";
import Callback from "./Callback";
import Dashboard from "./Dashboard";
import LandingPage from "./landingPage";
import SignUp from "./Form";
import LogoutCallback from "./LogoutCallback";
import Organizer from "./Organizer";
import AddInformation from "./AddInformation";
import Report from "./Report";
import PreviewReport from "./previewReport";
import AboutEcoIndex from "./aboutExoindex.js";
import FollowEcoIndex from "./FollowEcoindex.js";
import TermsAndConditions from "./TermsAndConditions.js";
import PrivacyPolicy from "./PrivacyPolicy.js";
import Resources from "./Resources.js";
import NewAddInfo from "./NewAddInfo.js";
import WalkThrough from "./walkthrough.js";
import RisingRajasthan from "./RisingRajasthan.js";
import EnergyConservationDay from "./EnergyConservationDay.js";
import ManuYantralaya from "./ManuYantralaya.jsx";
import Cage from "./Cage.jsx";
import PolyamideCage from "./PolyamideCage.jsx";
import DustShield from "./DustShield.jsx";
import ManuBmw from "./ManuBmw.jsx";
import TaperCage from "./TaperCage.jsx";
import NewAddInfoID from "./NewAddInfoID.jsx";
import Wefix from "./Wefix.js";

import WefixReport from "./WefixReport.js";


function App() {
  return (
    <Router>
      <EventProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin-oidc" element={<Callback />} />
          <Route path="/events" element={<EventsTable />} />
          <Route path="/new-event" element={<AddEvent />} />
          <Route path="/events/event-details" element={<EventDetails />} />
          <Route
            path="/events/during-event-planning/*"
            element={<DuringEventPlanning />}
          />
          <Route
            path="/events/post-event-planning/*"
            element={<PostEventPlanning />}
          />
          <Route
            path="/events/pre-event-planning/*"
            element={<PreEventPlanning />}
          />
          <Route path="/events/individual/*" element={<Individual />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/signout-callback-oidc" element={<LogoutCallback />} />
          <Route path="/events/Organizer/*" element={<Organizer />} />
          <Route path="/add-information" element={<AddInformation />} />
          <Route path="/rising-rajasthan" element={<RisingRajasthan />} />
          <Route path="/energy-conservation-day" element={<EnergyConservationDay />} />
          <Route path="/events/report" element={<Report />} />
          <Route path="/preview-report" element={<PreviewReport />} />
          <Route path="/aboutecoindex" element={<AboutEcoIndex />} />
          <Route path="/followecoindex" element={<FollowEcoIndex />} />
          <Route
            path="/terms_and_conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/newaddinfo" element={<NewAddInfo />} />
          <Route path="/newaddinfo/:id" element={<NewAddInfoID />} />
          <Route path="/walkthrough" element={<WalkThrough />} />
          <Route path="/manuyantralaya" element={<ManuYantralaya />} />
          <Route path="/manuyantralaya/cage" element={<Cage />} />
          <Route path="/manuyantralaya/polyamidecage" element={<PolyamideCage />} />
          <Route path="/manuyantralaya/bmwemission" element={<ManuBmw />} />
          <Route path="/manuyantralaya/dustshield" element={<DustShield/>} />
          <Route path="/manuyantralaya/tapercage" element={<TaperCage/>} />
       
          <Route path="/wefix" element={<Wefix />} />
          <Route path="/wefix-report" element={<WefixReport />} />

        </Routes>
      </EventProvider>
    </Router>
  );
}

export default App;

import React from "react";
import "./Resources.css";

function Resources() {
  return (
    <div className="resources-container">
      <h1 className="resources-heading">Resources</h1>
      <div className="resources-grid">
        {/* First Resource Card - PDF Download */}
        <div className="resource-card">
        <img
            src="/guide_img.png"
            alt="Practical Guide Image"
            className="resource-image"
          />
          <h3 className="resource-title">
          Pactical Guide for Net Zero Carbon Events
          </h3>
          <p className="resource-description">
          Transform every gathering into an eco-conscious celebration. This guide offers practical suggestions to help you create impactful, planet-friendly events.
            <br/> Download to understand more!
          </p>
         
          <a
            href="https://eventtool-api.octodosolutions.com/download/assets/Preparation_Guide_for_Sustainaable_Event.pdf"
            download="Preperation_Guide_for_Sustainaable_Event.pdf"
            className="read-more-button"
          >
            Download
          </a>
        </div>

        {/* Second Resource Card - PPT Download */}
        <div className="resource-card">
        <img
            src="/eventDataImg.png"
            alt="Checklist Image"
            className="resource-image"
          />
          <h3 className="resource-title">Checklist to capture data for EcoIndex: Your Path to Accurate Emission Tracking</h3>
          <p className="resource-description">
          A step-by-step guide to gathering essential data for accurate carbon emission tracking.
          </p>
          <a
            href="https://eventtool-api.octodosolutions.com/download/assets/Event_Data_Checklist.pptx" 
            download="Event_Data_Checklist.pptx"
            className="read-more-button2"
          >
            Download
          </a>
        </div>
        <div className="resource-card">
        <img
            src="/walkthrough.png"
            alt="Checklist Image"
            className="resource-image"
          />
          <h3 className="resource-title">Walkthrough for EcoIndex</h3>
          <p className="resource-description">
          This video will give you a complete walkthrough of ecoindex events
          <br />
          Otherwise, the application can be experienced using the link: ecoindex.ai 
          </p>
          <a
            href="/walkthrough" 
            target="_blank"
            className="read-more-button2"
          >
            Watch
          </a>
        </div>
      </div>
    </div>
  );
}

export default Resources;

import React, { useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import "./WefixPopup.css";
import Wefixcontact from "./assets/contact.png";

const WefixPopup = ({ totalEmission, onClose }) => {
  const [showContactPopup, setShowContactPopup] = useState(false);

  const getEmissionMessage = () => {
    if (totalEmission >= 160) {
      return "Oops! You exceeded the carbon emissions limit.";
    } else if (totalEmission >= 80) {
      return "You are within the carbon emissions limit.";
    } else {
      return "Great! You are below the carbon emission limit.";
    }
  };

  const getEmissionColor = () => {
    if (totalEmission >= 160) {
      return "#f00018"; // Red
    } else if (totalEmission >= 80) {
      return "#ffa342";
    } else {
      return "#4dbce8";
    }
  };

  const handleCloseContactPopup = () => {
    setShowContactPopup(false);
  };

  return (
    <div className="wefixpopUp-container">
      <div className="popup-overlay">
        <div className="popup-content">
          <button className="popup-close-button" onClick={onClose}>
            &times;
          </button>

          <div className="popup-row">
            <div className="wefixspeedometer-container">
              <div className="Wefix-para">
                <p>🍃Total Emission per day by the use of ACs</p>
              </div>
              <ReactSpeedometer
                width={240}
                height={240}
                needleTransitionDuration={4000}
                needleColor="black"
                value={Math.min(totalEmission || 0, 240)}
                minValue={0}
                maxValue={240}
                segments={3}
                segmentColors={["#4dbce8", "#ffa342", "#f00018"]}
                customSegmentStops={[0, 80, 160, 240]}
                forceRender={true}
                labelFontSize={"0px"}
                ringWidth={40}
                valueTextFontSize={"0px"}
              />

              <div
                className="wefixemission-message"
                style={{ color: getEmissionColor() }}
              >
                <h2>
                  {totalEmission >= 0
                    ? `${totalEmission.toFixed(2)} Kg CO₂`
                    : "Calculating..."}
                </h2>
                {getEmissionMessage()}
              </div>
            </div>
            <div className="wefix-pop-container">
              <h2>Explore our services</h2>
              <div className="Wefix-img33">
                <img src={Wefixcontact} alt="img76" />
              </div>
              <p>
                <strong>Email:</strong> support@wefixteam.com
              </p>
              <p>
                <strong>Phone:</strong> +91-9800587809
              </p>
            </div>
          </div>
        </div>
      </div>

      {showContactPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button
              className="popup-close-button"
              onClick={handleCloseContactPopup}
            >
              &times;
            </button>
            <h2>Contact Us</h2>
            <img
              src={Wefixcontact}
              alt="Contact"
              className="Wefix-contact-image"
            />
            <p>
              <strong>Email:</strong> support@wefixteam.com
            </p>
            <p>
              <strong>Phone:</strong> 8016856565
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default WefixPopup;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./NewAddInfoPopup.css";
import { getIndividualConsumptionDetailsV2 } from "./apiService";
import ReactSpeedometer from "react-d3-speedometer";
import ecologo from "./assets/ecologo.png";

function NewAddInfoID() {
  const { id } = useParams(); // Extract the ID from the URL
  const [emissionData, setEmissionData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEmissionData = async () => {
      try {
        const data = await getIndividualConsumptionDetailsV2(id);
        setEmissionData(data);
      } catch (error) {
        console.error("Error fetching emission data:", error);
        alert("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchEmissionData();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!emissionData) {
    return <div>No data available for ID {id}</div>;
  }

  const {
    eventLocation,
    accomodationEmissions,
    eventName,
    totalEmissions,
    travelEmissions,
    mealEmissions,
  } = emissionData;

 
 
  const travelPercentage =
    totalEmissions > 0
      ? ((travelEmissions || 0) / totalEmissions) * 100
      : 0;
  const accommodationPercentage =
    totalEmissions > 0
      ? ((accomodationEmissions || 0) / totalEmissions) * 100
      : 0;
  const foodPercentage =
    totalEmissions > 0
      ? ((mealEmissions || 0) / totalEmissions) * 100
      : 0;

    
      let emissionMessage = "";
      let additionalMessage = "";
      let messageStyle = {};


if (totalEmissions <= 80) {
  emissionMessage =
    "Great job! Your emissions are below the average for conference attendees. Keep up the good work.";
  messageStyle = { color: "" };
} else if (totalEmissions <= 160) {
  emissionMessage =
    "Well done! Your emissions are in line with the average for conference attendees. Every step counts toward sustainability!";
  messageStyle = { color: "" };
} else {
  // High emissions range
  emissionMessage =
    "Oops! Your emissions are higher than the average for conference attendees. ";
  messageStyle = { color: "" };

  // Additional messages for specific conditions
  if (travelPercentage > 50) {
    additionalMessage =
      "Try switching to greener transport options for the next event.";
  } else if (accommodationPercentage > 50) {
    additionalMessage =
      "Try switching to greener stay options for the next event.";
  }
}


  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-header">
          <img src={ecologo} alt="" srcset="" />
          <p>Your Emission</p>
         
        </div>

        <div className="popup-body">
          <div className="emission-summary">
            <div className="emission-summary-details">
              <p>Total Emission</p>
              <h1 className="total-emission">
                {" "}
                <b>{totalEmissions || 0}</b> Kg CO₂
              </h1>
              
              <div className="bar-container">
                
                <div className="skill-box">
                  <span className="bar-title">Accommodation: <b>{accomodationEmissions|| 0}</b> KgCO₂</span>
                  <div className="skill-bar">
                    <span
                      className="skill-per "
                      style={{
                        width: `${accommodationPercentage}%`,
                        background: "#FFD5F7",
                      }}
                    >
                  
                      <span className="bar-text">
                        {accommodationPercentage.toFixed(1)}%
                      </span>
                      
                    </span>
                  </div>
                </div>

                 
                <div className="skill-box">
                  <span className="bar-title">Travel: <b>{travelEmissions || 0}</b> KgCO₂</span>
                  <div className="skill-bar">
                    <span
                      className="skill-per "
                      style={{
                        width: `${travelPercentage}%`,
                        background: "#D7EBFB",
                      }}
                    >
                    
                      <span className="bar-text">
                        {travelPercentage.toFixed(1)}%
                      </span>
                    </span>
                  </div>
                </div>

                 
                <div className="skill-box">
                  <span className="bar-title">Food: <b>{mealEmissions || 0}</b> KgCO₂</span>
                  <div className="skill-bar">
                    <span
                      className="skill-per "
                      style={{
                        width: `${foodPercentage}%`,
                        background: "#F9F5C7",
                      }}
                    >
                      
                      <span className="bar-text">
                        {foodPercentage.toFixed(1)}%
                      </span>
                    </span>
                  </div>
                </div>

             


          
              </div>
            </div>

            <div className="emission-summary-meter">
              <div>
                <ReactSpeedometer
                  width={270}
                  height={180}
                  needleTransitionDuration={1000}
                  needleColor="black"
                  minValue={0}
                  maxValue={240}
                  segments={3}
                  segmentColors={["#4dbce8", "#ffa342", "#f00018"]}
                  customSegmentStops={[0, 80, 160, 240]}
                  labelFontSize={"0px"}
                  valueTextFontSize={"0px"}
                  value={totalEmissions || 0}
                />
              </div>
              <div className="popup-text">
    <p style={messageStyle}>
      
      <strong style={{ fontSize: "14px" }}>
        {emissionMessage.split(".")[0]}.
      </strong>
     
      <br />
      {emissionMessage.split(".").slice(1).join(".")}
    </p>
    {additionalMessage && (
      <p style={{ fontStyle: "italic", marginTop: "10px" }}>
        {additionalMessage}
      </p>
    )}
  </div>
            </div>
          </div>

          <div className="info-event">
    <div className="info-event-details">
        <h4>Event Details</h4>
        <p>
        <b>Name: </b>{eventName} <br/> <b>Location: </b>{eventLocation}
        </p>
    </div>
</div>
        </div>

      </div>

      
    </div>
  );
}

export default NewAddInfoID;

import React from "react";
import "./manucagenew.css";
import dustshieldnobg from "./assets/manu/dustshieldnobg.png";
import Lottie from "lottie-react";
import gear2 from "./assets/manu/gear2.json";
import dustprocess from './assets/manu/dustprocess.png';
import { FaCar } from 'react-icons/fa';
import { PiPlant } from "react-icons/pi"
// import manulogo from './assets/manu/manulogo.png'
import newmanu from './assets/manu/newmanu.jpeg'

const DustShield = () => {


  return (
    <div className="manu-main-container">
    <img src={newmanu} alt="Manu Logo" className="new-manu-logo"/>
   <div className="grid-container">
     <div className="box box-1">
       <div>
       <h2>PROCESS FLOW</h2>
       </div>
       <div>
     <img src={dustprocess} alt="" srcset="" />
     </div>
     </div>
     <div className=" box-2">METAL DUST SHIELD</div>
     <div className="box box-3">
       <img src={dustshieldnobg} alt="" srcset="" />
     </div>
     <div className="box box-4">
       <ul>
         <li>
           {" "}
           <Lottie animationData={gear2} className="gear2" />
           Total Emission (Production) <br></br>
         </li>
         <li className="box-highlighted-text">
           {" "}
           <span>181.33 </span>tCO₂
         </li>
         <li>
           {" "}
           <Lottie animationData={gear2} className="gear2" /> Per Piece
           Emission<br></br>
         </li>
         <li className="box-highlighted-text">
           {" "}
           <span>1.58 g </span>CO₂e
         </li>{" "}
         <li>
           {" "}
           <Lottie animationData={gear2} className="gear2" /> Specific Energy
           Consumption<br></br>
         </li>
         <li className="box-highlighted-text">
           {" "}
           <span>4.51 </span>W
         </li>
       </ul>
     </div>
     <div className="box box-5">
    <div>
    <FaCar />  <span> 9,06,672.71 KM</span>
    <p>The Passenger Car shall be driven to emit this<br/> amount of Carbon.</p>
    </div>
    <div>=</div>
    <div>
    <PiPlant /> <span> 7253</span>
    <p>Number of trees wll be required to absorb this<br/> amount of Carbon.</p>
    </div>
     </div>
   </div>
 </div>
  );
};

export default DustShield;

import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import Map from './assets/manu/map.json';
import "./manucagenew.css";
// import MANU from './assets/MANU.png';
// import manulogo from './assets/manu/manulogo.png'
import newmanu from './assets/manu/newmanu.jpeg'
import Vignette from './assets/manu/Vignette.png';
import carbonfootrpint3 from './assets/manu/carbonfootprint3.png'

const ManuYantralaya = () => {
    const [count, setCount] = useState(1); 
    const [showNewText, setShowNewText] = useState(false);

    useEffect(() => {
        const target = 20;
        const interval = 80;

        const counter = setInterval(() => {
            setCount((prev) => {
                if (prev < target) {
                    return prev + 1;
                } else {
                    clearInterval(counter);
                    return prev;
                }
            });
        }, interval);

        return () => clearInterval(counter);
    }, []);

    useEffect(() => {
        const loopInterval = setInterval(() => {
            setShowNewText((prev) => !prev); 
        }, 9000); 

        return () => clearInterval(loopInterval);
    }, []);

    return (
        <div className='manu-main-container'>
            <img src={newmanu} alt="Manu Logo" className="new-manu-logo"/>

            <div className='manu-home'>
                <div className="manu-animation">
                    <img src={Vignette} alt="Manu Logo" className = "vignette" />
                    <img src={carbonfootrpint3} alt="Manu Logo" className="footprint-img" />
                    <Lottie animationData={Map} className="Map" />
                </div>

                <div className={`manu-text ${showNewText ? 'hidden' : ''}`}>
                    <p>
                        Zero Carbon Footprint <br />
                        supplies to <span>{count} Countries</span>
                    </p>
                </div>

                {showNewText && (
                    <div className="new-text">
                       <p>DO YOU WISH TO SEE HOW<br></br>MUCH  <span> Carbon </span>WE ARE OFFSETTING?</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ManuYantralaya;



